import React from "react";
import { IoWarningOutline } from "react-icons/io5";
import { MdErrorOutline } from "react-icons/md";

function MsgBox(props) {
  const { msgValue, type } = props;

  const StyleError = {
    position: "fixed",
    top: "60px",
    zIndex: "999",
    width: "100%",
    padding: "5px",
    backgroundColor: "#fbfafaf1",
    borderTop: "#000 1px solid",
    borderBottom: "#000 1px solid",
    color: "#d53131",
  };

  const StyleSuccess = {
    width: "100%",
    padding: "5px",
    backgroundColor: "#d53131",
    color: "#ffffff",
  };

  const StyleWarning = {
    width: "100%",
    padding: "5px",
    backgroundColor: "#d53131",
    color: "#ffffff",
  };

  const StyleNotice = {
    width: "100%",
    padding: "5px",
    backgroundColor: "#d53131",
    color: "#ffffff",
  };

  return (
    <div
      style={
        type == "Error"
          ? { ...StyleError }
          : type == "Success"
          ? { ...StyleSuccess }
          : type == "Warning"
          ? { ...StyleWarning }
          : { ...StyleNotice }
      }
    >
      <p
        style={{
          display: "flex",
          width: "70%",
          margin: "0 auto",
          fontWeight: "bold",
        }}
      >
        <MdErrorOutline
          style={{ position: "relative", top: "2px", right: "3px" }}
        />
        {msgValue}
      </p>
    </div>
  );
}

export default MsgBox;

// MsgBox.defaultAtribute = {
//   type: "Error",
//   msgValue: "",
// };
