import React, { useEffect } from "react";
import "../components/styles.css";
import CSOfficeOutslide from "../resource/IMG_4464-min.webp";
import CSTalking from "../resource/pexels-christina-morillo-1181360-min.webp";
import CSOfficeInside from "../resource/IMG_4495-min.webp";
import ourOffice from "../resource/image-src-home/cybersoft_office.webp";
import OurCEO from "../resource/tekisteberhan habtu.jpeg";
import ourBuilding from "../resource/our_building.jpeg";
import Co_workers from "../resource/image-src-home/fiber-4814456_1280.webp";
import Co_workers_meeting from "../resource/image-src-home/cybersoft_office.webp";
import CountUp, { startAnimation } from "react-countup";

import AwesomeSlider from "react-awesome-slider";
import AwesomeSliderStyles from "react-awesome-slider/src/styles";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import VisibilitySensor from "react-visibility-sensor";
import CyberWorkers from "../resource/image-src-home/about-section-image.webp";
import ImagePattern from "../resource/ImagePattern.jpg";

import EATH from "../resource/EATH.png";
import AAU from "../resource/AAU.png";
import ETRE from "../resource/ETRE.png";
import MOJ from "../resource/MOJ.png";
import NVI from "../resource/NVI.png";
import ARRA from "../resource/ARRA.png";
import IES from "../resource/IES.jpg";
import FAO from "../resource/FAO.png";
import WV from "../resource/WV.png";
import EMA from "../resource/EMA.png";

import { SiDotnet, SiExpress, SiNextdotjs } from "react-icons/si";
import { FaAngular, FaNode, FaReact, FaVuejs } from "react-icons/fa";
import { IoArrowForwardSharp } from "react-icons/io5";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import Card from "../components/Card";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";

function Home() {
  const param = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  console.log("param", param.pathname);
  const navigate = useNavigate();
  const AutoplaySlider = withAutoplay(AwesomeSlider);

  const Core_Services_cards = [
    {
      text: "Consult",
      url: "",
    },
    {
      text: "Design",
      url: "",
    },
    {
      text: "Engineer",
      url: "",
    },
    {
      text: "Optimize",
      url: "",
    },
    {
      text: "Modernize",
      url: "/Services/modernization",
    },
  ];

  return (
    <>
      <div className="heading-main-container">
        <div className="home-container">
          <AutoplaySlider
            play={true}
            cancelOnInteraction={false} // should stop playing on user interaction
            interval={8000}
            mobileTouch={true}
            bullets={false}
            className="slider"
          >
            <div
              style={{
                backgroundImage: `url(${Co_workers_meeting})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              className="slid"
            >
              <div className="slid-content">
                <div className="section">
                  <span>
                    <strong></strong> Customer base
                  </span>
                  <h1 className="headliner">
                    <strong> We </strong> develop advanced software solutions
                  </h1>
                  {/* <NavLink className="link" to={"/"}> */}
                  <h1
                    className="-more"
                    style={{
                      maxWidth: "70%",
                      marginTop: "50px",
                      fontWeight: "100",
                    }}
                  >
                    Leveraging on our expertise and latest technologies we
                    provide distinct solutions to our customers challenges
                    {/* <IoArrowForwardSharp className="icon" id="icon" /> */}
                  </h1>
                  {/* </NavLink> */}
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundImage: `url(${Co_workers})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              className="slid"
            >
              <div className="slid-content">
                <div className="section">
                  <span>
                    <strong></strong> Position
                  </span>
                  <h1 className="headliner">
                    <strong> We are </strong>at the forefront in automating the
                    bussinesses of our valued clients for more than two decades
                  </h1>
                  <NavLink className="link" to={"/"}>
                    <strong>
                      Learn more
                      <IoArrowForwardSharp className="icon" id="icon" />
                    </strong>
                  </NavLink>
                </div>
              </div>
            </div>
          </AutoplaySlider>
        </div>
      </div>
      {/* <AwesomeSlider cssModule={AwesomeSliderStyles}>
            <div data-src={CSOfficeOutslide} />
            <div data-src={CSOfficeInside} />
          </AwesomeSlider> */}

      {/* <div
        style={{
          // backgroundImage: `url(${ImagePattern})`,
          backgroundImage: `url(${ImagePattern})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }}
      >
        <div className="pattern">
          <div className="message-content flex">
            <div className="section">
              <h1>
                <strong> We Develop</strong> Cutting-Edge Software Solutions.
              </h1>
              <span>
                Leveraging our expertise and latest technologies to bring your
                vision to life and to find solutions to your most challenging
                business problems.
              </span>
              <NavLink className="call-to-action" to={"/"}>
                <strong>
                  Schedule a Discovery Call{" "}
                  <IoArrowForwardSharp className="icon" id="icon" />
                </strong>
              </NavLink>
            </div>
        
          </div>
        </div>
      </div> */}

      <div className="message-content">
        <div className="section">
          <h1>
            <strong> Our </strong> Impact
          </h1>
          <span className="our-impact-detail">
            Our teams of experts have delivered powerful digital solutions for
            the past 25 years, helping businesses to embrace automation and to
            stay ahead. The numbers speak for themselves:
          </span>
          <NavLink className="link" to={"/"}>
            <strong className="light--mode">
              Learn more <IoArrowForwardSharp className="icon" id="icon" />
            </strong>
          </NavLink>
          <div className="figures">
            <div className="figure">
              <CountUp
                start={0}
                end={1998}
                duration={3}
                // delay={4}
                separator=""
                // decimals={4}
                // decimal=","
                // prefix="EUR "
                // suffix=" left"
                // onEnd={() => console.log("Ended! 👏")}
                // onStart={() => console.log("Started! 💨")}
              >
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <div>
                      <h3 className="our-impact-figure" ref={countUpRef}>
                        {" "}
                      </h3>
                      {/* <button onClick={start}>Start</button> */}
                    </div>
                  </VisibilitySensor>
                )}
              </CountUp>

              <p> Since </p>
            </div>
            <div className="figure">
              {/* <h1> */}
              <CountUp
                // delay={4}
                start={0}
                end={720}
                duration={3}
                redraw={true}
                // separator=" "
                // decimals={4}
                // decimal=","
                // prefix="EUR "
                // suffix=" left"
                // onEnd={() => console.log("Ended! 👏")}
                // onStart={() => console.log("Started! 💨")}
              >
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <div>
                      <h3 className="our-impact-figure" ref={countUpRef}></h3>
                      {/* <button onClick={start}>Start</button> */}
                    </div>
                  </VisibilitySensor>
                )}
              </CountUp>
              {/* </h1> */}
              <p> Customer base </p>
            </div>
            <div className="figure">
              <CountUp
                start={0}
                end={17}
                duration={7}
                // delay={4}
                // separator=" "
                // decimals={4}
                // decimal=","
                // prefix="EUR "
                // suffix=" left"
                // onEnd={() => console.log("Ended! 👏")}
                // onStart={() => console.log("Started! 💨")}
              >
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <div>
                      <h3 className="our-impact-figure" ref={countUpRef}></h3>
                      {/* <button onClick={start}>Start</button> */}
                    </div>
                  </VisibilitySensor>
                )}
              </CountUp>

              <p> Industries </p>
            </div>

            <div className="figure">
              <CountUp
                start={0}
                end={4}
                duration={7}
                // delay={4}
                // separator=" "
                // decimals={4}
                // decimal=","
                // prefix="EUR "
                // suffix=" left"
                // onEnd={() => console.log("Ended! 👏")}
                // onStart={() => console.log("Started! 💨")}
              >
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <div>
                      <h3 className="our-impact-figure" ref={countUpRef}></h3>
                      {/* <button onClick={start}>Start</button> */}
                    </div>
                  </VisibilitySensor>
                )}
              </CountUp>

              <p> Awards </p>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          // backgroundImage: `url(${ImagePattern})`,
          // backgroundImage: `url("https://img.freepik.com/free-vector/abstract-circular-halftone-design-background_1055-17747.jpg?t=st=1718634316~exp=1718637916~hmac=c490d70b10b2f6ad2bd4714cfe61e3abe76407004f127810ea44865461e1324a&w=1800")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
      >
        <div className="pattern">
          <div className="message-content flex">
            <div className="section">
              <h1>
                <strong> Core </strong> Services
              </h1>
              <span>
                We craft innovative software solutions that streamline
                workflows, boost efficiency, and drive results.
              </span>

              <div className="card-group">
                {Core_Services_cards.map((Core_Services_card, id) => (
                  <Card
                    key={id}
                    className="card-inside"
                    value={Core_Services_card.text}
                    onClick={() => {
                      navigate(Core_Services_card.url);
                    }}
                  />
                ))}
              </div>
              {/* <NavLink className="call-to-action" to={"/"}>
                <strong>
                  Discover more
                  <IoArrowForwardSharp className="icon" id="icon" />
                </strong>
              </NavLink> */}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundImage: `url(${CyberWorkers})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className="slid">
          <div className="message-content">
            <div className="section">
              <h1>
                <strong> About </strong> Cybersoft
              </h1>
              <span>
                Cybersoft was established in 1998 to deliver advanced software
                solutions and incisive consultancy services to its highly valued
                clients.
              </span>
              <NavLink className="link" to={"/"}>
                <strong>
                  Learn more <IoArrowForwardSharp className="icon" id="icon" />
                </strong>
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundImage: `url(${ImagePattern})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }}
      >
        <div className="our-tech-content-container pattern">
          <div className="our-tech-container">
            <div className="our-tech-content">
              <div className="section">
                <h1>
                  <strong> Who </strong> We work with
                </h1>

                <p className="text">
                  indicative thriving Businesses and Institutions Powered by
                  Cybersoft's solutions
                </p>

                <div className="figures">
                  <AutoplaySlider
                    play={true}
                    showTimer={false}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={4000}
                    mobileTouch={true}
                    bullets={true}
                    buttons={false}
                    className="customer-slider"
                  >
                    <div className="customer--slid">
                      <div className="figure">
                        <img className="customer-logo" src={EATH} />
                      </div>
                      <div className="figure">
                        <img className="customer-logo" src={AAU} />
                      </div>
                      <div className="figure">
                        <img className="customer-logo" src={ETRE} />
                      </div>
                      <div className="figure">
                        <img className="customer-logo" src={MOJ} />
                      </div>
                      <div className="figure">
                        <img className="customer-logo" src={WV} />
                      </div>
                    </div>

                    <div className="customer--slid">
                      <div className="figure">
                        <img className="customer-logo" src={NVI} />
                      </div>
                      <div className="figure">
                        <img className="customer-logo" src={ARRA} />
                      </div>
                      <div className="figure">
                        <img className="customer-logo" src={IES} />
                      </div>
                      <div className="figure">
                        <img className="customer-logo" src={FAO} />
                      </div>
                      <div className="figure">
                        <img className="customer-logo" src={EMA} />
                      </div>
                    </div>
                  </AutoplaySlider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="slide"> */}
      <div className="our-tech-content-container">
        <div className="our-tech-container">
          <div className="our-tech-content">
            <div className="section">
              <h1>
                <strong> Our </strong> Technology Stacks
              </h1>

              <p className="text">
                At Cybersoft, we're passionate about using the latest and
                effective technologies to craft innovative software solutions.
                Our tech stack is the foundation upon which we build secure,
                scalable, and high-performing products.
              </p>

              <div className="figures">
                <AutoplaySlider
                  play={true}
                  cancelOnInteraction={false} // should stop playing on user interaction
                  interval={4000}
                  mobileTouch={true}
                  bullets={false}
                  buttons={false}
                  className="tech-stack-slider"
                >
                  <div className="our-tech-stacks--slid">
                    <div className="figure">
                      <h1>
                        <FaReact /> React
                      </h1>
                    </div>
                    <div className="figure">
                      <h1>
                        <FaAngular /> Angular
                      </h1>
                    </div>
                    <div className="figure">
                      <h1>
                        <FaVuejs /> Vue
                      </h1>
                    </div>
                    <div className="figure">
                      <h1>
                        <SiNextdotjs /> Next.js
                      </h1>
                    </div>
                  </div>

                  <div className="our-tech-stacks--slid">
                    <div className="figure">
                      <h1>
                        <SiDotnet /> .Net
                      </h1>
                    </div>
                    <div className="figure">
                      <h1>
                        <FaNode /> Node.js
                      </h1>
                    </div>
                    <div className="figure">
                      <h1>
                        <SiExpress /> Express
                      </h1>
                    </div>
                  </div>
                </AutoplaySlider>
              </div>
              {/* <div className="figures no-space">
               





              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

      <div className="our-tech-content-container">
        <div className="our-tech-container">
          <div className="our-tech-content">
            <div className="section">
              <h1>
                <strong> </strong> Testimonials
              </h1>

              <p className="text">
                {/* At Cybersoft, we're passionate about using the latest and
                effective technologies to craft innovative software solutions.
                Our tech stack is the foundation upon which we build secure,
                scalable, and high-performing products. */}
              </p>

              <AutoplaySlider
                play={true}
                cancelOnInteraction={false} // should stop playing on user interaction
                interval={10000}
                mobileTouch={true}
                bullets={false}
                buttons={false}
                className="tech-stack-slider"
                style={{ height: "60vh", marginTop: "10px" }}
              >
                <div
                  // className="our-tech-stacks-"
                  style={{
                    backgroundColor: "#FBFAFA",
                    display: "flex",
                    flexDirection: "column",
                    padding: "30px",
                  }}
                >
                  <img
                    src={EATH}
                    style={{
                      width: "100px",
                      height: "100px",
                      marginLeft: "auto",
                    }}
                  />
                  <b> East African trading House PLC </b>
                  <span
                    style={{
                      fontSize: "18px",
                      padding: "10px",
                      maxWidth: "40%",
                      textAlign: "center",
                      textTransform: "unset",
                    }}
                  >
                    <p style={{ width: "80%" }}>
                      {" "}
                      <RiDoubleQuotesL />
                      Recently EATH embarked on automating its management,
                      operations, and activities and contracted Cybersoft p.l.c
                      to provide it. While working on the implementation of
                      Cybersoft's ERP and other developed solutions EATH has
                      been happily surprised about Cybersoft's timely project
                      management as well as the responsiveness of its solutions.
                      <RiDoubleQuotesR />
                    </p>
                  </span>
                </div>

                <div
                  // className="our-tech-stacks-"
                  style={{
                    backgroundColor: "#FBFAFA",
                    display: "flex",
                    flexDirection: "column",
                    padding: "30px",
                  }}
                >
                  <img src={FAO} style={{ width: "100px", height: "100px" }} />
                  <b> Food and Agriculture Organization (FAO) Addis Ababa </b>

                  <span
                    style={{
                      fontSize: "18px",
                      padding: "10px",
                      textAlign: "center",
                      textTransform: "lowercase",
                    }}
                  >
                    <p style={{ width: "80%" }}>
                      {" "}
                      <RiDoubleQuotesL />
                      Cybersoft had undertaken the system analysis and
                      development of application software modules for FAO.
                      Cybersoft duly completed its above undertakings
                      successfully. Therefore, I am pleased to recommend
                      Cybersoft p.l.c. to any potential client.
                      <RiDoubleQuotesR />
                    </p>
                  </span>
                </div>
              </AutoplaySlider>

              {/* <div className="figures no-space">
               





              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
