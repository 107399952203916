import React, { useEffect, useState } from "react";
import AwesomeSlider from "react-awesome-slider";
import { IoArrowForwardSharp } from "react-icons/io5";
import { NavLink, useLocation } from "react-router-dom";
import HeadingImage from "../../resource/Heading_background.png";
import ETH_image from "../../resource/EATH_image.jpeg";
import TraconTrading_image from "../../resource/traconTrading_image.png";
import Industries_image from "../../resource/Industries_image.webp";
import "../../components/styles.css";
import NavGroup from "../../components/NavGroup";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import Card from "../../components/Card";

const Core_Services_cards = [
  {
    text: "Retail",
    url: "/Services/modernization",
  },
  {
    text: "Travel",
    url: "",
  },
  {
    text: "Hospitality",
    url: "",
  },
];

const linkList = [
  {
    id: "1",
    name: "Commercial Transformation",
    text: "Commercial Transformation",
    detail: `We partner with you to drive efficiency and effectiveness to increase market share and topline growth through strategy aided by technology.`,
    url: "/",
    style: {},
  },
  {
    id: "2",
    name: "Supply Chain Digitization",
    text: "Supply Chain Digitization",
    detail: `We optimize your supply chain networks to drive triple-bottom-line impact – financial, environmental and societal.`,
    url: "/",
    style: {},
  },
];

function Consumer() {
  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState();

  const [activeDetail, setActiveDetail] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    setActiveDetail(linkList[0]);
    setActiveRoute(location.pathname);
  }, []);

  const AutoplaySlider = withAutoplay(AwesomeSlider);

  useEffect(() => {
    window.scrollTo(0, 0);
    setActiveRoute(location.pathname);
  }, []);

  return (
    <>
      <div
        className="heading-container"
        style={{
          backgroundImage: `url(${Industries_image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top",
          height: "85vh",
        }}
      >
        <div
          style={{
            // backdropFilter: "blur(20px)",
            backgroundColor: "#00000068",
            width: "100%",
            height: "85vh",
            color: "#ffffff",
          }}
        >
          <div className="heading--content">
            <h1>
              <strong> Consumer </strong>
            </h1>
            <span>
              We help consumer businesses successfully navigate, grow and
              optimize within a dynamic market.
            </span>
            <NavLink className="call-to-action" to={"/solutions"}>
              <strong>
                Explore Our Solutions
                <IoArrowForwardSharp className="icon" id="icon" />
              </strong>
            </NavLink>
          </div>
        </div>
      </div>

      <div
        className="heading-container"
        style={{
          backgroundImage: `url(${HeadingImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          color: "black",
        }}
      >
        <div className="main-section-content">
          <h1>
            <strong> Commercial Transformation</strong>
          </h1>
          <span>
            We partner with you to drive efficiency and effectiveness to
            increase market share and topline growth through strategy aided by
            technology.
          </span>
          <NavLink className="call-to-action" to={activeRoute}>
            <strong>
              Learn more
              <IoArrowForwardSharp className="icon" id="icon" />
            </strong>
          </NavLink>
        </div>
      </div>

      <div className="home-container">
        <AutoplaySlider
          play={true}
          cancelOnInteraction={false} // should stop playing on user interaction
          interval={8000}
          mobileTouch={true}
          bullets={false}
          className="slider"
          style={{ height: "60vh" }}
        >
          <div
            style={{
              backgroundImage: `url(${ETH_image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            className="slid"
          >
            <div className="slid-content">
              <div className="section">
                <span>
                  <strong></strong> East African trading House PLC
                </span>
                <h1 className="headliner">
                  <strong> We </strong> automated its management, operations,
                  and activities.
                </h1>
                <NavLink className="link" to={"/"}>
                  <strong>
                    Learn more
                    <IoArrowForwardSharp className="icon" id="icon" />
                  </strong>
                </NavLink>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${TraconTrading_image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            className="slid"
          >
            <div className="slid-content">
              <div className="section">
                <span>
                  <strong></strong> Tracon Trading PLC
                </span>
                <h1 className="headliner">
                  <strong>
                    {" "}
                    We implemented fully integrated ERP
                  </strong>
                </h1>
                <NavLink className="link" to={"/"}>
                  <strong>
                    Learn more
                    <IoArrowForwardSharp className="icon" id="icon" />
                  </strong>
                </NavLink>
              </div>
            </div>
          </div>
        </AutoplaySlider>
      </div>

      <div
        style={{
          // backgroundImage: `url(${ImagePattern})`,
          //   backgroundImage: `url("https://img.freepik.com/free-vector/abstract-circular-halftone-design-background_1055-17747.jpg?t=st=1718634316~exp=1718637916~hmac=c490d70b10b2f6ad2bd4714cfe61e3abe76407004f127810ea44865461e1324a&w=1800")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
      >
        <div className="pattern">
          <div className="message-content">
            <div className="section">
              <h1>
                <strong> Market </strong> Sectors
              </h1>
              <div className="card-group">
                {Core_Services_cards.map((Core_Services_card, id) => (
                  <Card
                    key={id}
                    className="card-inside"
                    value={Core_Services_card.text}
                    onClick={() => {
                      //   navigate(Core_Services_card.url);
                    }}
                  />
                ))}
              </div>

              <div className="card-group">
                <NavLink className="call-to-action" to={activeRoute}>
                  <strong>
                    Learn more about our opperations
                    <IoArrowForwardSharp className="icon" id="icon" />
                  </strong>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="heading-container"
        style={{
          //   backgroundImage: `url(${CoreServicesImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }}
      >
        <div className="main-section-content">
          <h1>
            <strong> Core Focus Areas </strong>
          </h1>
          <div className="list-switch-container">
            <NavGroup
              links={linkList}
              onClick={(e) => {
                setActiveDetail(e);
              }}
              activeLink={activeDetail.name}
            />
            <div className="switch-detail">
              <h2> {activeDetail.text} </h2>
              <p>{activeDetail.detail}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Consumer;
