import React, { useEffect, useState } from "react";
import { IoArrowForwardSharp } from "react-icons/io5";
import { NavLink, useLocation } from "react-router-dom";
import HeadingImage from "../../resource/Heading_background.png";
import CoreModernizationImage from "../../resource/core_modernization_image.png";
import CoreServicesImage from "../../resource/core_services_section_image.png";
import "../../components/styles.css";
import NavGroup from "../../components/NavGroup";

const linkList = [
  {
    id: "1",
    name: "Legacy Application Migration",
    text: "Legacy Application Migration",
    detail: `Migrating from manual or outdated legacy systems to modern platforms can be a complex undertaking demanding meticulous planning. We leverage automation to streamline processes where applicable. Drawing on our extensive experience, we have successfully helped numerous organizations. These migrations have helped them to offer more comprehensive services.`,
    url: "/",
    style: {},
  },
  {
    id: "2",
    name: "Building an API ecosystem",
    text: "Building an API ecosystem",
    detail: `API ecosystem is a network of interconnected applications and services that communicate. It involves partners, customers, and a robust platform to support them. By focusing on these elements and addressing potential challenges, we create a thriving API ecosystem that drives your business`,
    url: "/",
    style: {},
  },
  {
    id: "3",
    name: "Building Data-centric transformation",
    text: "Building Data-centric transformation",
    detail: `Unlock your business potential through data-driven decision making. We craft tailored data strategies and navigation, implementing practical solutions like data factories, DataOps, and data virtualization. By democratizing data within your core systems, we empower your teams to access and utilize information effectively, driving innovation and growth.`,
    url: "/",
    style: {},
  },
  {
    id: "4",
    name: "architectural design applying",
    text: "Architectural design applying",
    detail: `A successful modernization initiative demands a forward-thinking architectural blueprint that adheres to industry best practices. Our team of experts leverages proven methodologies and cutting-edge technologies to create a scalable and adaptable architecture. By combining robust roadmaps, effective patterns, and optimization strategies, we deliver innovative solutions that drive long-term business value.`,
    url: "/",
    style: {},
  },
  {
    id: "5",
    name: "modernization strategy",
    text: "Modernization strategy",
    detail: `Modernization strategy is the process of updating outdated software systems to align with current technological advancements and business needs. It involves a strategic approach to enhance performance, security, scalability, and overall efficiency.`,
    url: "/",
    style: {},
  },
  {
    id: "6",
    name: "an incremental modernization",
    text: "An incremental modernization",
    detail: `A phased modernization strategy can yield substantial improvements to your platforms, applications, or mainframes while minimizing costs and disruptions. Our optimization services help reduce operational expenses, enhance system reliability, and eliminate technical debt. By upgrading to newer versions, you can extend system lifespan, maintain compliance, and unlock performance gains. 
              We seamlessly integrate legacy development practices into modern DevOps workflows to boost agility and efficiency.`,
    url: "/",
    style: {},
  },
];

function Modernization() {
  const location = useLocation();
  const [activeDetail, setActiveDetail] = useState({});
  const [activeRoute, setActiveRoute] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    setActiveDetail(linkList[0]);
    setActiveRoute(location.pathname);
  }, []);

  return (
    <>
      <div
        className="heading-container"
        style={{
          backgroundImage: `url(${HeadingImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }}
      >
        <div className="heading--content">
          <h1>
            <strong> Modernization </strong>
          </h1>
          <span>
            Fuel rapid growth with modern platforms, applications, and data.
          </span>
          <NavLink className="call-to-action" to={activeRoute}>
            <strong>
              Explore Our Solutions
              <IoArrowForwardSharp className="icon" id="icon" />
            </strong>
          </NavLink>
        </div>
      </div>

      <div
        className="heading-container"
        style={{
          // backgroundImage: `url(${HeadingImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }}
      >
        <div className="main-section-content">
          <h1>
            <strong style={{ fontSize: "35px" }}>
              Revitalize your core business systems by migrating them to modern,
              cloud-based architectures that deliver enhanced performance,
              reliability, and flexibility.
            </strong>
          </h1>
          <span>
            By adopting Cybersoft’s advanced solutions, clients can embark on a
            modernization journey, to significantly enhance their organizational
            and operational efficiency and effectiveness.
          </span>
        </div>
      </div>

      <div
        className="heading-container"
        style={{
          backgroundImage: `url(${CoreModernizationImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          color: "white",
        }}
      >
        <div className="main-section-content">
          <h1>
            <strong>Core modernization services</strong>
          </h1>
          <span>
            Our platforms fuel rapid growth with advanced applications and
            management of data.
          </span>
          <NavLink className="call-to-action" to={activeRoute}>
            <strong>
              Explore Our Solutions
              <IoArrowForwardSharp className="icon" id="icon" />
            </strong>
          </NavLink>
        </div>
      </div>

      <div
        className="heading-container"
        style={{
          backgroundImage: `url(${CoreServicesImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }}
      >
        <div className="main-section-content">
          <h1>
            <strong> Core Services </strong>
          </h1>
          <div className="list-switch-container">
            <NavGroup
              links={linkList}
              onClick={(e) => {
                setActiveDetail(e);
              }}
              activeLink={activeDetail.name}
            />
            <div className="switch-detail">
              <h2> {activeDetail.text} </h2>
              <p>{activeDetail.detail}</p>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          // backgroundImage: `url(${ImagePattern})`,
          backgroundImage: `url("https://img.freepik.com/free-vector/abstract-circular-halftone-design-background_1055-17747.jpg?t=st=1718634316~exp=1718637916~hmac=c490d70b10b2f6ad2bd4714cfe61e3abe76407004f127810ea44865461e1324a&w=1800")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
      >
        <div className="pattern">
          <div className="message-content">
            <div className="section">
              <h1>
                <strong> Featured </strong> Solutions
              </h1>
              <span>Combination of enterprise software and applications</span>

              <div className="card-group">
                <NavLink className="call-to-action" to={activeRoute}>
                  <strong>
                    Explore Our Solutions
                    <IoArrowForwardSharp className="icon" id="icon" />
                  </strong>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Modernization;
