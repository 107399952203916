// import React, { useState, useEffect } from "react";
import {
  Route,
  BrowserRouter,
  Routes,
  useNavigate,
  Navigate,
  useParams,
  useLocation,
} from "react-router-dom";

import "./App.css";
import Header from "./components/header";
import NotFound from "./pages/notFound";
import Home from "./pages/Home";
import Footer from "./components/footer";
import Modernization from "./pages/Services/modernization";
import Engineering from "./pages/Services/engineering";
import Optimize from "./pages/Services/optimizing";
import Education from "./pages/Industries/education";
import Consumer from "./pages/Industries/consumer";
import Solutions from "./pages/Solutions/solutions";
import AboutCompany from "./pages/About/aboutCompany";
import Contact from "./pages/Contact/contact";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />

          <Route path="/Services">
            <Route path="/Services/modernization" element={<Modernization />} />
            <Route path="/Services/engineering" element={<Engineering />} />
            <Route path="/Services/optimize" element={<Optimize />} />
          </Route>
          <Route path="/Industries">
            <Route path="/Industries/education" element={<Education />} />
            <Route path="/Industries/resources" element={<Engineering />} />
            <Route path="/Industries/financial" element={<Optimize />} />
            <Route path="/Industries/consumer" element={<Consumer />} />
          </Route>
          <Route path="/Solutions" element={<Solutions />} />
          <Route path="/About">
            <Route path="/About/company" element={<AboutCompany />} />
          </Route>
          <Route path="/Contact">
            <Route path="/Contact/contact-us" element={<Contact />} />
          </Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

//
// Financial Services
// Consumer
