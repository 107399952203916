import React from "react";
import { IoArrowForwardSharp } from "react-icons/io5";
import { NavLink } from "react-router-dom";

function NavGroup(props) {
  const { links, onClick, activeLink } = props;

  //   console.log(links);

  return (
    <div>
      <div className="links">
        {links.map((link) => (
          <div
            key={link.id}
            className={activeLink == link.name ? "link_active" : "link"}
          >
            <NavLink
              onClick={() => {
                onClick(link);
              }}
              className={activeLink == link.name ? "link_active" : "link"}
              to={""}
            >
              <strong>
                {link.text}
                <IoArrowForwardSharp className="icon" id="icon" />
              </strong>
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NavGroup;
