import React, { useEffect, useState } from "react";
import AwesomeSlider from "react-awesome-slider";
import Autocomplete from "@mui/material/Autocomplete";
import { IoArrowForwardSharp } from "react-icons/io5";
import { NavLink, useLocation } from "react-router-dom";
import HeadingImage from "../../resource/Heading_background.png";
import HeadlinerImage from "../../resource/image-src-home/cybersoft_office.webp";
import "../../components/styles.css";
import NavGroup from "../../components/NavGroup";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import Card from "../../components/Card";
import { Checkbox, TextField } from "@mui/material";
import MsgBox from "../../components/MsgBox";
import { db } from "../../services/firebase";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";

const customData = require("../../services/countries.json");

const Core_Services_cards = [
  {
    text: "Submit Inquiry",
    url: "",
  },
];

const linkList = [
  {
    id: "1",
    name: "Commercial Transformation",
    text: "Commercial Transformation",
    detail: `We partner with you to drive efficiency and effectiveness to increase market share and topline growth through strategy aided by technology.`,
    url: "/",
    style: {},
  },
  {
    id: "2",
    name: "Supply Chain Digitization",
    text: "Supply Chain Digitization",
    detail: `We optimize your supply chain networks to drive triple-bottom-line impact – financial, environmental and societal.`,
    url: "/",
    style: {},
  },
];

const address_links = [
  {
    name: "P +251-118-111440",
    url: "",
  },
  {
    name: `Bete berhan Bldg • ECA Rd, 
              Addis Ababa, Ethiopia`,
    url: "",
  },
  {
    name: "P.O BOX: 32666",
    url: "",
  },
];

const Inquiry = [
  { title: "General information Request" },
  { title: "Talk to our sales" },
  { title: "Careers" },
  { title: "Employment Verification" },
  { title: "Investor Relations" },
  { title: "Website Feedback" },
];

function Contact() {
  const location = useLocation();

  const [activeRoute, setActiveRoute] = useState();
  const [activeDetail, setActiveDetail] = useState({});
  const [InquiryObject, setInquiryObject] = useState({
    Inquiry: "",
    InquiryType: "",
    fistName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    position: "",
    location: "",
    city: "",
    inquiryChecked: false,
    dateTime: Date.now()
  });

  const placesRef = collection(db, "cyber_web_inquiry");

  async function SendInquiryToFirestore() {
    addDoc(placesRef, InquiryObject).then(() => {
      setInquirySubmitted(true);
    });
  }

  const [inquirySubmitted, setInquirySubmitted] = useState(false);
  const [listOfCounters, setListOfCounters] = useState([]);
  const [messages, setMessages] = useState("");
  const [messagesType, setMessagesType] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    setActiveDetail(linkList[0]);
    setActiveRoute(location.pathname);
  }, []);

  const defaultProps = {
    options: Inquiry,
    getOptionLabel: (option) => option.title,
  };

  const countryLookup = {
    options: listOfCounters,
    getOptionLabel: (option) => option.title,
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  useEffect(() => {
    setListOfCounters(customData);
  }, []);

  console.log("messages", messages);

  return (
    <>
      {messages != "" && <MsgBox msgValue={messages} type={"Error"} />}

      <div
        className="heading-container"
        style={{
          backgroundImage: `url(${HeadlinerImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "85vh",
        }}
      >
        <div
          style={{
            // backdropFilter: "blur(20px)",
            backgroundColor: "#000000a6",
            width: "100%",
            height: "85vh",
            color: "#ffffff",
          }}
        >
          <div className="heading--content">
            <h1>
              <strong> Get in touch with us </strong>
            </h1>
            <span>
              Take the first step and we will help you every step of the way
            </span>
            <NavLink className="call-to-action" to={"/solutions"}>
              <strong>
                Explore Our Solutions
                <IoArrowForwardSharp className="icon" id="icon" />
              </strong>
            </NavLink>
          </div>
        </div>
      </div>

      <div
        className="heading-container"
        style={{
          //   backgroundImage: `url(${HeadingImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          color: "black",
        }}
      >
        <div className="main-section-content">
          <h1>
            <strong> Our Address </strong>
          </h1>
          <div className="footer-links">
            {address_links.map((address_link, id) => (
              <NavLink key={id} className="link" to={address_link.url}>
                {address_link.name}
              </NavLink>
            ))}
          </div>
        </div>
      </div>

      <div
        className="heading-container"
        style={{
          backgroundImage: `url(${HeadingImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          color: "black",
        }}
      >
        <div className="main-section-content">
          {inquirySubmitted == true ? (
            <>
              <h1>
                <strong>
                  {" "}
                  Thank you for your inquiry, We will try our best to respond at
                  our earliest{" "}
                </strong>
              </h1>
            </>
          ) : (
            <>
              <h1>
                <strong> Submit your inquiry </strong>
              </h1>
              <form className="form-inquiry">
                <Autocomplete
                  {...defaultProps}
                  id="clear-on-escape"
                  clearOnEscape
                  onChange={(e) => {
                    setInquiryObject({
                      ...InquiryObject,
                      ["InquiryType"]: e.target.innerText,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select the Reason for Your Inquiry*"
                      variant="standard"
                    />
                  )}
                />
                <div className="form-items">
                  <TextField
                    label="First Name*"
                    variant="standard"
                    className="form-items--item"
                    onChange={(e) => {
                      setInquiryObject({
                        ...InquiryObject,
                        ["fistName"]: e.target.value,
                      });
                    }}
                  />
                  <TextField
                    label="Last Name"
                    variant="standard"
                    className="form-items--item"
                    onChange={(e) => {
                      setInquiryObject({
                        ...InquiryObject,
                        ["lastName"]: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-items">
                  <TextField
                    label="Email Address*"
                    variant="standard"
                    className="form-items--item"
                    onChange={(e) => {
                      setInquiryObject({
                        ...InquiryObject,
                        ["email"]: e.target.value,
                      });
                    }}
                  />
                  <TextField
                    label="Phone Number"
                    variant="standard"
                    className="form-items--item"
                    onChange={(e) => {
                      setInquiryObject({
                        ...InquiryObject,
                        ["phone"]: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-items">
                  <TextField
                    label="Company"
                    variant="standard"
                    className="form-items--item"
                    onChange={(e) => {
                      setInquiryObject({
                        ...InquiryObject,
                        ["company"]: e.target.value,
                      });
                    }}
                  />
                  <TextField
                    label="Position"
                    variant="standard"
                    className="form-items--item"
                    onChange={(e) => {
                      setInquiryObject({
                        ...InquiryObject,
                        ["position"]: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-items">
                  <Autocomplete
                    {...countryLookup}
                    id="clear-on-escape"
                    clearOnEscape
                    className="form-items--item"
                    onChange={(e) => {
                      setInquiryObject({
                        ...InquiryObject,
                        ["location"]: e.target.innerText,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Location*"
                        variant="standard"
                      />
                    )}
                  />
                  <TextField
                    label="City"
                    variant="standard"
                    className="form-items--item"
                    onChange={(e) => {
                      setInquiryObject({
                        ...InquiryObject,
                        ["city"]: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-items">
                  <TextField
                    label="Your inquiry"
                    variant="standard"
                    className="form-items--item-Inquiry"
                    onChange={(e) => {
                      setInquiryObject({
                        ...InquiryObject,
                        ["Inquiry"]: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="consent-box">
                  <Checkbox
                    {...label}
                    className="checkBox"
                    onChange={(event) => {
                      setInquiryObject({
                        ...InquiryObject,
                        ["inquiryChecked"]: event.target.checked,
                      });
                    }}
                  />
                  <p>
                    I consent to Cybersoft, P.l.c.(Cybersoft) processing my
                    personal information as set out in the{" "}
                    <strong> Privacy Policy </strong>
                    and that, given the global nature of Cybersoft's business,
                    such processing may take place outside of my home
                    jurisdiction.
                  </p>
                </div>

                <div className="card-group">
                  {Core_Services_cards.map((Core_Services_card, id) => (
                    <Card
                      key={id}
                      className="card-inside-inquiry"
                      value={Core_Services_card.text}
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          InquiryObject.Inquiry != "" &&
                          InquiryObject.InquiryType != "" &&
                          InquiryObject.fistName != "" &&
                          InquiryObject.lastName != "" &&
                          InquiryObject.email != "" &&
                          InquiryObject.location != "" &&
                          InquiryObject.inquiryChecked != false
                        ) {
                          SendInquiryToFirestore();
                        } else {
                          setMessagesType("Success");
                          if (InquiryObject.InquiryType == "") {
                            setMessages(`We need your inquiry type`);
                          } else if (InquiryObject.fistName == "") {
                            setMessages(`We need your first name`);
                          } else if (InquiryObject.lastName == "") {
                            setMessages(`We need your last name`);
                          } else if (InquiryObject.email == "") {
                            setMessages(`We need your email`);
                          } else if (InquiryObject.location == "") {
                            setMessages(`We need your location`);
                          }
                          if (InquiryObject.Inquiry == "") {
                            setMessages(`We need your Inquiry`);
                          } else if (InquiryObject.inquiryChecked == false) {
                            setMessages(
                              `We need your consent to collect your personal information`
                            );
                          }
                          setTimeout(() => {
                            setMessages("");
                          }, 5000);
                        }
                      }}
                    />
                  ))}
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Contact;
