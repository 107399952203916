import React, { useEffect, useState } from "react";
import { IoArrowForwardSharp } from "react-icons/io5";
import { NavLink, useLocation } from "react-router-dom";
import HeadingImage from "../../resource/Heading_background.png";
import HeadlineBg from "../../resource/image-src-home/optimize_image.webp";
import "../../components/styles.css";
import NavGroup from "../../components/NavGroup";

const linkList = [
  {
    id: "1",
    name: "Digital Transformation",
    text: "Digital Transformation",
    detail: `We enable you transform your organization to achieve the results you’re looking for.`,
    url: "/",
    style: {},
  },
  {
    id: "2",
    name: "Strategic Spending",
    text: "Strategic Spending",
    detail: `We help you allocate your funds in a way that supports your overall business objectives.`,
    url: "/",
    style: {},
  },
];

function Optimize() {
  const location = useLocation();

  const [activeDetail, setActiveDetail] = useState({});
  const [activeRoute, setActiveRoute] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    setActiveDetail(linkList[0]);
    setActiveRoute(location.pathname);
  }, []);

  return (
    <>
      <div
        className="heading-container"
        style={{
          backgroundImage: `url(${HeadlineBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
      >
        <div
          style={{
            backdropFilter: "blur(10px)",
            height: "70vh",
            width: "100%",
            color: "#fff",
          }}
        >
          <div className="heading--content">
            <h1>
              <strong> Optimizing for Growth</strong>
            </h1>
            <span>
              We help achieve growth by aligning your resources to your
              strategy.
            </span>
            <NavLink
              style={{ color: "#fff" }}
              className="call-to-action"
              to={"/solutions"}
            >
              <strong>
                Explore Our Solutions
                <IoArrowForwardSharp className="icon" id="icon" />
              </strong>
            </NavLink>
          </div>
        </div>
      </div>

      <div
        className="heading-container"
        style={{
          backgroundImage: `url(${HeadingImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          color: "black",
        }}
      >
        <div className="main-section-content">
          <h1>
            <strong> We take holistic view of your business </strong>
          </h1>
          <span>
            We’re operators who take a holistic view of your business to
            identify high-impact and low-impact areas to redirect your resources
            appropriately.
          </span>
          <NavLink className="call-to-action" to={activeRoute}>
            <strong>
              Learn more
              <IoArrowForwardSharp className="icon" id="icon" />
            </strong>
          </NavLink>
        </div>
      </div>

      <div
        className="heading-container"
        style={{
          //   backgroundImage: `url(${CoreServicesImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
        }}
      >
        <div className="main-section-content">
          <h1>
            <strong> Focus Areas </strong>
          </h1>
          <div className="list-switch-container">
            <NavGroup
              links={linkList}
              onClick={(e) => {
                setActiveDetail(e);
              }}
              activeLink={activeDetail.name}
            />
            <div className="switch-detail">
              <h2> {activeDetail.text} </h2>
              <p>{activeDetail.detail}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Optimize;
