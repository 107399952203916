import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../resource/Logo.png";
import { FaFacebook, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";

function Footer() {
  const navigate = useNavigate();

  const conpany_links = [
    {
      name: "Home",
      url: "",
    },
    {
      name: "Services",
      url: "",
    },
    {
      name: "Industries",
      url: "",
    },
    {
      name: "About",
      url: "",
    },
    {
      name: "Careers",
      url: "",
    },
    {
      name: "Contact Us",
      url: "",
    },
  ];

  const services_links = [
    {
      name: "Consult",
      url: "",
    },
    {
      name: "Design",
      url: "",
    },
    {
      name: "Engineer",
      url: "",
    },
    {
      name: "Modernize",
      url: "",
    },
    {
      name: "Optimize",
      url: "",
    },
  ];

  const partners_links = [
    {
      name: "Investors",
      url: "",
    },
    {
      name: "Partnership",
      url: "",
    },
  ];

  const address_links = [
    {
      name: "P +251-118-111440",
      url: "",
    },
    {
      name: `Bete berhan Bldg • ECA Rd, 
              Addis Ababa, Ethiopia`,
      url: "",
    },
    {
      name: "Ask Us Anything",
      url: "",
    },
  ];

  const socials = [
    {
      name: "linkedIn",
      url: "",
      icon: <FaLinkedin fontSize={20} />,
    },
    {
      name: "linkedIn",
      url: "",
      icon: <FaFacebook fontSize={20} />,
    },
    {
      name: "linkedIn",
      url: "",
      icon: <FaYoutube fontSize={20} />,
    },
    {
      name: "linkedIn",
      url: "",
      icon: <FaTwitter fontSize={20} />,
    },
  ];

  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-logo">
          <img
            src={Logo}
            onClick={() => {
              navigate("/");
            }}
          />
        </div>

        <div className="rights">
          <p>
            © 2024 Cybersoft, P.L.C. All Rights Reserved. Cybersoft and the
            Cybersoft logo are registered trademarks of Cybersoft, P.L.C.
          </p>
        </div>

        <div className="footer-nav-container">
          <div className="cat">
            <div className="title">
              <h2> Company </h2>
            </div>
            <div className="footer-links">
              {conpany_links.map((conpany_link, id) => (
                <NavLink key={id} className="link" to={conpany_link.url}>
                  {conpany_link.name}
                </NavLink>
              ))}
            </div>
          </div>

          <div className="cat">
            <div className="title">
              <h2> Services </h2>
            </div>
            <div className="footer-links">
              {services_links.map((services_link, id) => (
                <NavLink key={id} className="link" to={services_link.url}>
                  {services_link.name}
                </NavLink>
              ))}
            </div>
          </div>

          <div className="cat">
            <div className="title">
              <h2> Partnership </h2>
            </div>
            <div className="footer-links">
              {partners_links.map((partners_link, id) => (
                <NavLink key={id} className="link" to={partners_link.url}>
                  {partners_link.name}
                </NavLink>
              ))}
            </div>
          </div>

          <div className="cat">
            <div className="title">
              <h2> Address </h2>
            </div>
            <div className="footer-links">
              {address_links.map((address_link, id) => (
                <NavLink key={id} className="link" to={address_link.url}>
                  {address_link.name}
                </NavLink>
              ))}
            </div>

            <div className="cat">
              <div className="title">
                <h2> Social </h2>
              </div>
              <div className="footer-link-icons">
                {socials.map((social, id) => (
                  <NavLink key={id} className="link" to={social.url}>
                    {social.icon}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
