import React, { useEffect, useState } from "react";
import { IoArrowForwardSharp } from "react-icons/io5";
import { NavLink, useLocation } from "react-router-dom";
import HeadingImage from "../../resource/Heading_background.png";
import "../../components/styles.css";


import CyberWorkers from "../../resource/IMG_4464-min.webp";

import { VscDebugBreakpointData } from "react-icons/vsc";
import Card from "../../components/Card";
import CountUp, { startAnimation } from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const Core_Services_cards = [
  {
    text: "Design",
    url: "/Services/modernization",
  },
  {
    text: "Implement",
    url: "",
  },
  {
    text: "Scale out",
    url: "",
  },
];

function AboutCompany() {
  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    setActiveRoute(location.pathname);
  }, []);

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${CyberWorkers})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "bottom center",
        }}
      >
        <div className="slid">
          <div className="message-content">
            <div className="section">
              <h1>
                <strong> About </strong> Cybersoft
              </h1>
              <span style={{ fontSize: "30px", fontWeight: "100" }}>
                Cybersoft was established in 1998 to deliver advanced software
                solutions and incisive consultancy services to its highly valued
                clients.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="message-content">
        <div className="section">
          <h1>
            <strong> Our </strong> Impact
          </h1>
          <span className="our-impact-detail">
            Our teams of experts have delivered powerful digital solutions for
            the past 25 years, helping businesses to embrace automation and to
            stay ahead. The numbers speak for themselves:
          </span>

          <div className="figures">
            <div className="figure">
              <CountUp
                start={0}
                end={1998}
                duration={3}
                // delay={4}
                separator=""
                // decimals={4}
                // decimal=","
                // prefix="EUR "
                // suffix=" left"
                // onEnd={() => console.log("Ended! 👏")}
                // onStart={() => console.log("Started! 💨")}
              >
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <div>
                      <h3 className="our-impact-figure" ref={countUpRef}>
                        {" "}
                      </h3>
                      {/* <button onClick={start}>Start</button> */}
                    </div>
                  </VisibilitySensor>
                )}
              </CountUp>

              <p> Since </p>
            </div>
            <div className="figure">
              {/* <h1> */}
              <CountUp
                // delay={4}
                start={0}
                end={720}
                duration={3}
                redraw={true}
                // separator=" "
                // decimals={4}
                // decimal=","
                // prefix="EUR "
                // suffix=" left"
                // onEnd={() => console.log("Ended! 👏")}
                // onStart={() => console.log("Started! 💨")}
              >
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <div>
                      <h3 className="our-impact-figure" ref={countUpRef}></h3>
                      {/* <button onClick={start}>Start</button> */}
                    </div>
                  </VisibilitySensor>
                )}
              </CountUp>
              {/* </h1> */}
              <p> Customer base </p>
            </div>
            <div className="figure">
              <CountUp
                start={0}
                end={17}
                duration={7}
                // delay={4}
                // separator=" "
                // decimals={4}
                // decimal=","
                // prefix="EUR "
                // suffix=" left"
                // onEnd={() => console.log("Ended! 👏")}
                // onStart={() => console.log("Started! 💨")}
              >
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <div>
                      <h3 className="our-impact-figure" ref={countUpRef}></h3>
                      {/* <button onClick={start}>Start</button> */}
                    </div>
                  </VisibilitySensor>
                )}
              </CountUp>

              <p> Industries </p>
            </div>

            <div className="figure">
              <CountUp
                start={0}
                end={4}
                duration={7}
                // delay={4}
                // separator=" "
                // decimals={4}
                // decimal=","
                // prefix="EUR "
                // suffix=" left"
                // onEnd={() => console.log("Ended! 👏")}
                // onStart={() => console.log("Started! 💨")}
              >
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <div>
                      <h3 className="our-impact-figure" ref={countUpRef}></h3>
                      {/* <button onClick={start}>Start</button> */}
                    </div>
                  </VisibilitySensor>
                )}
              </CountUp>

              <p> Awards </p>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
      >
        <div className="pattern">
          <div className="message-content">
            <div className="section">
              <h1>
                <strong> How </strong> We Work With You
              </h1>
              <div className="card-group">
                {Core_Services_cards.map((Core_Services_card, id) => (
                  <Card
                    key={id}
                    className="card-inside"
                    value={Core_Services_card.text}
                    onClick={() => {
                      //   navigate(Core_Services_card.url);
                    }}
                  />
                ))}
              </div>

              <div className="card-group">
                <strong style={{ color: "#4eb0e2" }}>
                  <a
                    className="call-to-action"
                    href="mailto:thabtu@cybersoft-intl.com"
                  >
                    Learn more about our opperations
                  </a>

                  <IoArrowForwardSharp className="icon" id="icon" />
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutCompany;
