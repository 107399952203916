import React from "react";

// interface Props {
//     value: any;
//     icon?: any;
//     name: string;
//     style: {
//         background?: string;
//         fontSize?: number;
//         fontWeight?: number;
//         width?: string;
//         height?: number;
//         padding?: string;
//         margin?: string;
//         marginTop?: number;
//         marginRight?: string;
//         color?: string;
//         borderColor?: string;
//         borderRadius?: number;
//         border?: string;
//         cursor?: string;
//         textDecoration: string;
//         display?: string;

//     };
//     // htmlType?: 'submit' | 'button' | 'reset' | undefined
//     // type: 'primary'
//     onClick?: (e: React.MouseEvent<HTMLElement>) => void;
// }

const Card = (props) => {
  // const { t } = useTranslation()
  const { value, className, icon, style, onClick } = props;
  const id = `${value}TextArea`;
  return (
<div className="card-out">
    <button
      style={{
        ...style,
      }}
      className={className}
      onClick={onClick}
    >
      {icon} {value}
    </button>
</div>
  );
};
Card.defaultProps = {
  value: "",
  style: {
    fontSize: 24,
    alignSelf: "right",
    textDecoration: "none",
  },
};

export default Card;
